<template>
  <table class="characteristics-table">
    <tbody>
      <tr
        v-for="row in content"
        :key="row?.PlaneDescriptionTitle"
      >
        <td>{{row?.PlaneDescriptionTitle}}</td>
        <td>{{row?.PlaneDecriptionValue}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'CharacteristicsTable',
  props: {
    content: Array,
  },
};
</script>

<style lang="scss" scoped>
.characteristics-table {
  display: block;
  width: 100%;

  @include wRule(
    font-size,
    (
      xl: 20,
      lg: 18,
      md: 16,
      sm: 30,
    )
  );

  tbody {
    display: block;
    width: 100%;
  }

  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    &:not(:last-child) {
      border-bottom: 1px solid color(accordion-border);
    }

    @include wRule(
      height,
      (
        xl: 42,
        lg: 39,
        md: 34,
        sm: 64,
      )
    );
  }

  td {
    &:first-child {
      opacity: 0.6;
    }

    &:last-child {
      @include wRule(
        margin-left,
        (
          xl: 10,
          lg: 10,
          md: 10,
          sm: 10,
        )
      );
      flex: none;
      white-space: nowrap;
    }
  }
}
</style>
