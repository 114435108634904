<template>
  <div
    class="certificate-card"
    @click.prevent="openedPopup"
    :data-slide="slideId"
  >
    <div class="icon">
      <icon name="blank" />
    </div>
    <div class="describe">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Icon from '../../Icon.vue';

export default {
  name: 'CertificateCard',
  components: {
    Icon,
  },
  props: {
    slideId: {
      type: [Number, String],
    },
  },
  emits: ['openPopup'],
  methods: {
    openedPopup() {
      this.$emit('openPopup', this.slideId);
    },
  },
};
</script>

<style lang="scss" scoped>
.certificate-card {
  $b: &;
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;

  @include screen("sm", max) {
    display: flex;
    align-items: center;
    height: pxtovw(258, sm);
    box-sizing: border-box;
    padding: pxtovw(25, sm) pxtovw(45, sm);
  }

  .describe {
    display: block;
    box-sizing: border-box;

    @include wRule(
      padding,
      (
        xl: 12 15 15 12,
        lg: 15 14,
        md: 9 11,
        sm: 0 0 0 66,
      )
    );

    @include wRule(
      bottom,
      (
        xl: 25,
        lg: 22,
        md: 18,
      )
    );
    @include wRule(
      left,
      (
        xl: 47,
        lg: 41,
        md: 35,
      )
    );

    @include screen("sm", min) {
      position: absolute;
      background-color: color(dark);
      right: 0;
    }

    @include screen("sm", max) {
      flex: 1;
      left: auto;
      bottom: auto;
    }
  }

  .icon {
    color: color(main);
    transition: color $ease-main;

    @include wRule(
      width,
      (
        xl: 195,
        lg: 172,
        md: 148,
        sm: 104,
      )
    );
    @include wRule(
      height,
      (
        xl: 284,
        lg: 250,
        md: 210,
        sm: 151,
      )
    );
  }

  @media (hover: hover) {
    &:hover {
      .icon {
        color: color(bg);
      }
    }
  }
}
</style>
