<template>
  <div class="aircraft-card">
    <div class="image">
      <picture>
        <img
          :src="getUrl(image?.url)"
          :alt="image?.alternativeText"
        >
      </picture>
      <div class="points">
        <div
          class="point"
          v-for="(tooltip, tooltipId) in tooltips"
          :key="`point-${image?.alternativeText}-${tooltipId}`"
          :style="`top: ${tooltip?.TooltipPositionTop * 0.1}%; left: ${tooltip?.TooltipPositionLeft * 100 / 1423}%`"
        >
          <VTooltip
            :triggers="['click']"
            :offset="[15, 15]"
            :placement="'top'"
            :disabled="breakpoint === 'sm'"
            :autoHide="true"
            theme="point"
          >
            <template #default>
              <button
                type="button"
                class="point-inner"
              >{{tooltipId + 1}}</button>
            </template>

            <template #popper>
              <div class="tooltip-text">
                {{tooltip?.PlaneDescriptionListItemText}}
              </div>
            </template>
          </VTooltip>
        </div>
      </div>
    </div>
    <div
      class="container accordion"
      v-observe-visibility="animation"
    >
      <PlaneAccordion :content="tooltips" />
    </div>
  </div>
</template>

<script>
import { animationMixin } from '@/components/mixins/animation';
import getUrl from '@/tools/getUrl';
import PlaneAccordion from './PlaneAccordion.vue';

export default {
  components: { PlaneAccordion },
  name: 'AircraftCard',
  mixins: [animationMixin],
  props: {
    image: {
      type: String,
      required: true,
    },
    tooltips: {
      type: Array,
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    getUrl,
  },
};
</script>

<style lang="scss" scoped>
.aircraft-card {
  display: block;
  width: 100%;

  .image {
    position: relative;

    @include ratio(1423, 1000);

    picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .points {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .point {
    position: absolute;
    display: block;
    background-color: color(basic);
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0);
    line-height: 0;

    @include wRule(
      width,
      (
        xl: 30,
        lg: 25,
        md: 20,
        sm: 30,
      )
    );
    @include wRule(
      height,
      (
        xl: 30,
        lg: 25,
        md: 20,
        sm: 30,
      )
    );
  }

  .point-inner {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    font-weight: 400;
    width: 100%;
    height: 100%;
    color: color(basic-light);
    line-height: 1;

    @include wRule(
      font-size,
      (
        xl: 15,
        lg: 13,
        md: 13,
        sm: 20,
      )
    );

    @include wRule(
      width,
      (
        xl: 30,
        lg: 25,
        md: 20,
        sm: 30,
      )
    );
    @include wRule(
      height,
      (
        xl: 30,
        lg: 25,
        md: 20,
        sm: 30,
      )
    );
  }

  .accordion {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: opacity 0.3s ease 0.8s, transform 0.3s ease 0.8s;

    @include rule(
      display,
      (
        xl: none,
        sm: block,
      )
    );

    @include wRule(
      margin-top,
      (
        sm: 50,
      )
    );

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}
</style>
