<template>
  <div
    class="certificates-slider animation"
    v-observe-visibility="animation"
  >
    <div class="container">
      <swiper
        :slides-per-view="1"
        :slides-per-column="3"
        :watchOverflow="true"
        :resize-observer="true"
        :observer="true"
        :observeSlideChildren="true"
        :simulateTouch="false"
        :breakpoints="{'768': { 'slidesPerView': 3, 'slidesPerColumn': 1}, '1484': { 'slidesPerView': 4, 'slidesPerColumn': 1}}"
        @resize="resizeObserver"
        @observerUpdate="resizeObserver"
        @after-init="setHeightForSm"
        @swiper="sliderInit"
      >
        <swiper-slide
          v-for="(certificate, index) in certificates"
          :key="index"
        >
          <certificate-card
            :slideId="index"
            @open-popup="openPopup"
          >
            {{ certificate.Title }}
          </certificate-card>
        </swiper-slide>
      </swiper>
    </div>
    <teleport to="#popups">
      <transition name="fade-slide-up">
        <popup
          v-if="popupActive"
          :isShown="popupActive"
          :isNoHorPad="true"
          :noScroll="true"
          @modal-closed="popupActive = false"
        >
          <template #content>
            <certificates-gallery
              :certificates="certificates"
              :initialSlide="clickedSlide"
            />
          </template>
        </popup>
      </transition>
    </teleport>
  </div>
</template>

<script>
import { animationMixin } from '@/components/mixins/animation';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';

import CertificateCard from './components/CertificateCard.vue';
import Popup from '../Popup.vue';
import CertificatesGallery from './components/CertificatesGallery.vue';

export default {
  name: 'CertificateSlider',
  mixins: [animationMixin],
  emits: ['sliderInit'],
  data() {
    return {
      popupActive: false,
      clickedSlide: 0,
    };
  },
  props: {
    navBlockName: {
      type: String,
    },
    certificates: {
      type: Array,
      required: true,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    CertificateCard,
    Popup,
    CertificatesGallery,
  },
  methods: {
    sliderInit(evt) {
      this.$emit('sliderInit', evt);
    },
    arrSplit(arr, chunks) {
      const result = [...Array(chunks)].map((_, c) => arr.filter((n, i) => i % chunks === c));
      return result;
    },
    setHeightForSm(swp) {
      const sw = swp;
      const bp = this.$store.getters.getBreakpoint;
      sw.el.style.height = '';
      if (bp === 'sm') {
        const arrayHeights = [];
        sw.slides.each((el) => {
          arrayHeights.push(el.offsetHeight);
        });
        const chuncks = Math.ceil(arrayHeights.length / 3);
        const arrayChuncks = this.arrSplit(arrayHeights, chuncks);
        const chunkHeight = [];
        arrayChuncks.forEach((agg) => {
          chunkHeight.push(agg.reduce((accumulator, currentValue) => accumulator + currentValue));
        });
        const totalHeight = Math.max.apply(null, chunkHeight);
        sw.el.style.height = `${totalHeight + 10}px`;
        sw.update();
      }
    },
    resizeObserver(sw) {
      sw.slides.each((el) => {
        const slide = el;
        slide.style.width = '';
      });
      sw.update();
      this.setHeightForSm(sw);
    },
    openPopup(id) {
      this.clickedSlide = id;
      this.popupActive = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.certificates-slider {
  width: 100%;

  &.animation {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  &.is-animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s ease 0.8s, transform 0.5s ease 0.8s;
  }

  .container {
    @include screen("sm", max) {
      padding: 0;
    }
  }

  .swiper-slide {
    box-sizing: border-box;

    @include wRule(
      width,
      (
        xl: 381,
        lg: 334,
        md: 286,
      )
    );

    @include wRule(
      padding-right,
      (
        xl: 50,
        lg: 30,
        md: 20,
        sm: 0,
      )
    );

    @include screen("sm", max) {
      width: 100%;
      height: auto !important;
      box-sizing: border-box;
    }
  }
}

.popup {
  ::v-deep(.popup__wrapper) {
    @include screen("sm", max) {
      overflow: auto !important;
    }
  }
}
</style>
