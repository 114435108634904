<template>
  <div class="aircraft-slider">
    <div class="container">
      <div class="control">
        <div
          class="descriptions"
          v-observe-visibility="animation"
        >
          <swiper
            :speed="500"
            effect="fade"
            :fadeEffect="{crossFade: true}"
            :allowTouchMove="false"
            @swiper="descrSwiper = $event"
          >
            <swiper-slide
              class="description"
              v-for="(aircraft, index) in aircrafts"
              :key="`description-${index}`"
              v-slot="{isActive}"
            >
              <h4
                class="title"
                :class="{'is-animated': isActive}"
              >{{aircraft?.Name}}</h4>
              <CharacteristicsTable
                :content="aircraft?.PlaneDescriptionOption"
                :class="{'is-animated': isActive}"
              />
            </swiper-slide>
          </swiper>
        </div>

        <slider-navigation
          :isDark="true"
          :swiper="swiperInstance"
        />
      </div>
    </div>
    <swiper
      class="map"
      :speed="500"
      effect="fade"
      :fadeEffect="{crossFade: true}"
      :slides-per-view="1"
      :watchOverflow="true"
      :simulateTouch="false"
      @swiper="swiperInstance = $event"
      @active-index-change="switchDescr"
      v-observe-visibility="animation"
    >
      <swiper-slide
        v-for="(aircraft, index) in aircrafts"
        :key="`map-area${index}`"
      >
        <aircraft-card
          :image="aircraft?.Picture"
          :alt="aircraft?.Name"
          :tooltips="aircraft?.ListOption"
        ></aircraft-card>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import SwiperCore, { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import AircraftCard from './components/AircraftCard.vue';
import SliderNavigation from '../SliderNavigation.vue';
import getUrl from '@/tools/getUrl';
import CharacteristicsTable from './components/CharacteristicsTable.vue';

import 'swiper/swiper.scss';

import { animationMixin } from '@/components/mixins/animation';

SwiperCore.use([EffectFade]);

export default {
  name: 'AircraftSlider',
  mixins: [animationMixin],
  props: {
    aircrafts: {
      type: Array,
      required: true,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    AircraftCard,
    SliderNavigation,
    CharacteristicsTable,
  },
  data() {
    return {
      swiperInstance: {},
      descrSwiper: {},
    };
  },
  computed: {
    currentActive() {
      return this.swiperInstance.activeIndex;
    },
  },
  methods: {
    getUrl,
    switchDescr(sw) {
      this.descrSwiper.slideTo(sw.activeIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
.aircraft-slider {
  display: block;
  width: 100%;

  .map {
    display: block;
    right: 0;
    opacity: 0;
    transition: opacity 0.3s ease 0.7s, transform 0.3s ease 0.7s;

    @include wRule(
      top,
      (
        xl: 10,
        lg: 10,
        md: 22.3%,
        sm: 0,
      )
    );

    @include wRule(
      margin-top,
      (
        sm: -65,
      )
    );

    @include rule(
      width,
      (
        xl: 74.1%,
        lg: 68.9%,
        md: 64%,
        sm: 100%,
      )
    );

    @include rule(
      position,
      (
        xl: absolute,
        sm: static,
      )
    );

    &.is-animated {
      opacity: 1;
    }
  }

  .control {
    position: relative;
    z-index: 2;

    @include screen("sm", max) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    @include wRule(
      width,
      (
        xl: 303,
        lg: 270,
        md: 245,
        sm: 100%,
      )
    );
  }

  .descriptions {
    width: 100%;
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: all 0.5s ease 0.6s;

    &.fade-enter-active {
      transition: all 0.3s ease;
    }

    &.fade-leave-active {
      transition: all 0.2s ease;
    }

    &.is-animated {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  .title {
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.6;
    opacity: 1;
    transition: opacity 0.3s ease;

    @include wRule(
      font-size,
      (
        xl: 24,
        lg: 22,
        md: 20,
        sm: 40,
      )
    );
  }

  .characteristics-table {
    width: 100%;
    opacity: 1;
    transition: opacity 0.3s ease;

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 44,
          lg: 42,
          md: 49,
          sm: 25,
        )
      );
    }
  }

  .slider-navigation {
    @include wRule(
      margin-left,
      (
        xl: -9,
        lg: -8,
        md: -8,
        sm: 0,
      )
    );

    @include screen("sm", max) {
      align-self: center;
    }

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 55,
          lg: 40,
          md: 48,
          sm: 40,
        )
      );
    }
  }

  ::v-deep(.swiper-slide) {
    pointer-events: none;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  ::v-deep(.swiper-slide-active) {
    pointer-events: auto;
  }
}
</style>
