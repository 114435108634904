<template>
  <button
    type="button"
    ref="closeTooltips"
    v-close-popper.all
    hidden
  >Close All Tooltips</button>
  <transition-group
    name="fade"
    mode="out-in"
  >
    <swiper
      v-if="breakpoint==='lg' || breakpoint==='xl'"
      class="about-page"
      :class="{'start-init': startInit}"
      :speed="500"
      :autoHeight="true"
      :slidesPerView="'auto'"
      :followFinger="false"
      :direction="'veritcal'"
      :keyboard="{enabled: true}"
      :mousewheel="true"
      :observer="true"
      :runCallbacksOnInit="false"
      :observeSlideChildren="true"
      :resizeObserver="true"
      :simulateTouch="false"
      @swiper="swiperInstance = $event"
      @slideNextTransitionStart="slideMoveDown"
      @slidePrevTransitionStart="slideMoveUp"
      @reachEnd="sliderReachEnd"
      @fromEdge="sliderGoAwayFromEnd"
      @beforeSlideChangeStart="startInit = false"
      @slideChangeTransitionStart="$refs.closeTooltips.click()"
    >
      <!-- Main -->
      <swiper-slide v-slot="{isActive, isPrev}">
        <page-section
          class="page-section_pale fullpage about-lead"
          :class="{'is-leave-animation': isPrev && isDown, 'is-enter-animation': (isActive && !isDown)}"
        >
          <template #background>
            <bg-image>
              <picture>
                <source
                  v-if="sectionMain?.BackgroundMobile"
                  :srcset="getUrl(sectionMain?.BackgroundMobile?.url)"
                  :type="sectionMain?.BackgroundMobile?.mime"
                  media="(max-width: 767px)"
                />
                <source
                  v-if="sectionMain?.Background"
                  :srcset="getUrl(sectionMain?.Background?.url)"
                  :type="sectionMain?.Background?.mime"
                />
                <img
                  :src="getUrl(sectionMain?.Background?.url)"
                  alt="@@"
                >
              </picture>
            </bg-image>
          </template>
          <template #header>
            <breadcrumbs></breadcrumbs>
          </template>
          <template #content>
            <div class="container">
              <div class="column column_start">
                <h3
                  class="animation"
                  v-observe-visibility="animation"
                >{{ sectionMain?.Title }}</h3>
              </div>
              <div class="column column_end">
                <div class="features">
                  <feature
                    v-for="(feature, index) in features"
                    :key="index"
                    :iconUrl="feature?.Icon?.url"
                    :text="feature?.Text"
                    class="feature_horizontal"
                  />
                </div>
              </div>
            </div>
          </template>
        </page-section>
      </swiper-slide>

      <!-- Airplanes -->
      <swiper-slide v-slot="{isActive, isNext, isPrev}">
        <page-section
          :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}"
          class="page-section_light fullpage about-airplanes"
        >
          <template #header>
            <div class="container">
              <div class="page-section__header">
                <h3
                  class="animation"
                  v-observe-visibility="animation"
                >{{ sectionPlanes?.Title }}</h3>
              </div>
            </div>
            <AircraftSlider
              class="plane"
              :aircrafts="planesArray"
            />
          </template>
        </page-section>
      </swiper-slide>

      <!-- Engineers --->
      <swiper-slide v-slot="{isActive, isNext, isPrev}">
        <page-section
          :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}"
          class="page-section_pale fullpage about-engineers"
        >
          <template #background>
            <bg-image>
              <picture>
                <source
                  v-if="sectionEngineers?.BackgroundMobile"
                  :srcset="getUrl(sectionEngineers?.BackgroundMobile?.url)"
                  :type="sectionEngineers?.BackgroundMobile?.mime"
                  media="(max-width: 767px)"
                />
                <source
                  v-if="sectionEngineers?.Background"
                  :srcset="getUrl(sectionEngineers?.Background?.url)"
                  :type="sectionEngineers?.Background?.mime"
                />
                <img
                  :src="getUrl(sectionEngineers?.Background?.url)"
                  alt="@@"
                >
              </picture>
            </bg-image>
          </template>
          <template #content>
            <div class="container">
              <div class="column">
                <h3
                  class="animation"
                  v-observe-visibility="animation"
                >{{ sectionEngineers?.Title }}</h3>
                <p
                  class="animation"
                  v-observe-visibility="animation"
                >{{ sectionEngineers?.Text }}</p>
              </div>
            </div>
          </template>
        </page-section>
      </swiper-slide>

      <!-- Certificates --->
      <swiper-slide v-slot="{isActive, isNext, isPrev}">
        <page-section
          :class="{'is-leave-animation': isPrev && isDown, 'is-active-animation': isActive && isDown, 'is-active-reverse-animation':  isNext && !isDown, 'is-enter-animation': (isActive && !isDown)}"
          class="page-section_pale fullpage about-certificates"
        >
          <template #background>
          </template>
          <template #header>
            <div class="container">
              <div class="page-section__header">
                <h3
                  class="animation"
                  v-observe-visibility="animation"
                >{{ sectionCertificates?.Title }}</h3>
                <slider-navigation :swiper="certificatesSwiperInstance" />
              </div>
            </div>
          </template>
          <template #content>
            <certificates-slider
              :certificates="sectionCertificates?.Certificates"
              nav-block-name="certificates-nav"
              @slider-init="certificatesSwiperInstance = $event"
            />
          </template>
        </page-section>
      </swiper-slide>

      <!-- Footer --->
      <swiper-slide v-slot="{isActive, isNext, isPrev}">
        <page-footer :class="{'is-leave-animation': isPrev, 'is-active-animation': isActive, 'is-enter-animation': isNext}"></page-footer>
      </swiper-slide>
    </swiper>

    <section
      class="about-page"
      v-if="breakpoint==='md' || breakpoint==='sm'"
    >
      <!-- Main --->
      <div class="swiper-slide">
        <page-section class="page-section_pale fullpage about-lead">
          <template #background>
            <bg-image>
              <picture>
                <source
                  v-if="sectionMain?.BackgroundMobile"
                  :srcset="getUrl(sectionMain?.BackgroundMobile?.url)"
                  :type="sectionMain?.BackgroundMobile?.mime"
                  media="(max-width: 767px)"
                />
                <source
                  v-if="sectionMain?.Background"
                  :srcset="getUrl(sectionMain?.Background?.url)"
                  :type="sectionMain?.Background?.mime"
                />
                <img
                  :src="getUrl(sectionMain?.Background?.url)"
                  alt="@@"
                >
              </picture>
            </bg-image>
          </template>
          <template #header>
            <breadcrumbs></breadcrumbs>
          </template>
          <template #content>
            <div class="container">
              <div class="column column_start">
                <h3
                  class="animation"
                  v-observe-visibility="animation"
                >{{ sectionMain?.Title }}</h3>
              </div>
              <div class="column column_end">
                <div class="features">
                  <feature
                    v-for="(feature, index) in features"
                    :key="index"
                    :iconUrl="feature?.Icon?.url"
                    :text="feature?.Text"
                    class="feature_horizontal"
                  />
                </div>
              </div>
            </div>
          </template>
        </page-section>
      </div>

      <!-- Aircrafts --->
      <div class="swiper-slide">
        <page-section class="page-section_light fullpage about-airplanes">
          <template #header>
            <div class="container">
              <div class="page-section__header">
                <h3
                  class="animation"
                  v-observe-visibility="animation"
                >{{ sectionPlanes?.Title }}</h3>
              </div>
            </div>
            <AircraftSlider
              class="plane"
              :aircrafts="planesArray"
            />
          </template>
        </page-section>
      </div>

      <!-- Engineers --->
      <div class="swiper-slide">
        <page-section class="page-section_pale fullpage about-engineers">
          <template #background>
            <bg-image>
              <picture>
                <source
                  v-if="sectionEngineers?.BackgroundMobile"
                  :srcset="getUrl(sectionEngineers?.BackgroundMobile?.url)"
                  :type="sectionEngineers?.BackgroundMobile?.mime"
                  media="(max-width: 767px)"
                />
                <source
                  v-if="sectionEngineers?.Background"
                  :srcset="getUrl(sectionEngineers?.Background?.url)"
                  :type="sectionEngineers?.Background?.mime"
                />
                <img
                  :src="getUrl(sectionEngineers?.Background?.url)"
                  alt="@@"
                >
              </picture>
            </bg-image>
          </template>
          <template #content>
            <div class="container">
              <div class="column">
                <h3
                  class="animation"
                  v-observe-visibility="animation"
                >{{ sectionEngineers?.Title }}</h3>
                <p
                  class="animation"
                  v-observe-visibility="animation"
                >{{ sectionEngineers?.Text }}</p>
              </div>
            </div>
          </template>
        </page-section>
      </div>

      <!-- Certificates --->
      <div class="swiper-slide">
        <page-section class="page-section_pale fullpage about-certificates">
          <template #background>
          </template>
          <template #header>
            <div class="container">
              <div class="page-section__header">
                <h3
                  class="animation"
                  v-observe-visibility="animation"
                >{{ sectionCertificates?.Title }}</h3>
                <slider-navigation :swiper="certificatesSwiperInstance" />
              </div>
            </div>
          </template>
          <template #content>
            <certificates-slider
              :certificates="sectionCertificates?.Certificates"
              nav-block-name="certificates-nav"
              @slider-init="certificatesSwiperInstance = $event"
            />
          </template>
        </page-section>
      </div>

      <!-- Footer --->
      <div class="swiper-slide">
        <page-footer></page-footer>
      </div>
    </section>
  </transition-group>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import SwiperCore, { Keyboard, Mousewheel } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/vue';
import Breadcrumbs from './Breadcrumbs.vue';
import PageSection from './PageSection.vue';
import BgImage from './BgImage.vue';
import Feature from './Feature.vue';
import CertificatesSlider from './CertificatesSlider/CertificatesSlider.vue';
import SliderNavigation from './SliderNavigation.vue';
import AircraftSlider from './AircraftSlider/AircraftSlider.vue';
import PageFooter from './PageFooter.vue';
import { animationMixin } from '@/components/mixins/animation';
import 'swiper/swiper.scss';
import api from '@/tools/api';
import getUrl from '@/tools/getUrl';

SwiperCore.use([Keyboard, Mousewheel]);

export default {
  name: 'AboutPage',
  emits: [
    'pageSliderScrollDown',
    'pageSliderScrollUp',
    'pageSliderReachFooter',
    'pageSliderGoAwayFooter',
  ],
  mixins: [animationMixin],
  components: {
    Swiper,
    SwiperSlide,
    Breadcrumbs,
    PageSection,
    BgImage,
    Feature,
    CertificatesSlider,
    SliderNavigation,
    AircraftSlider,
    PageFooter,
  },
  data() {
    return {
      startInit: true,
      sliderKey: 'desktop',
      pageContent: {},
      certificatesSwiperInstance: {},
      isDown: false,
      swiperInstance: undefined,
    };
  },
  methods: {
    slideMoveDown() {
      this.$emit('pageSliderScrollDown', this.swiperInstance?.activeIndex);
      this.isDown = true;
    },
    slideMoveUp() {
      this.isDown = false;
      this.$emit('pageSliderScrollUp', this.swiperInstance?.activeIndex);
    },
    sliderReachEnd() {
      this.$emit('pageSliderReachFooter');
    },
    sliderGoAwayFromEnd() {
      this.$emit('pageSliderGoAwayFooter');
    },
    stopStartAnimation(sw) {
      const active = sw.activeIndex;
      const activeSlide = sw.slides[active];
      const activePage = activeSlide.querySelector('.page-section');
      if (activePage) {
        activePage.classList.remove('is-enter-animation');
      }
    },
    closeAllTooltips() {
      this.$refs.closeTooltips.click();
    },
    async getPageContent() {
      this.pageContent = await api('page-about-content', true);
    },
    async getPlanes() {
      this.planes = await api('planes', true);
    },
    getUrl,
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    currentLang() {
      return this.$store.getters.getLang;
    },
    sectionMain() {
      return this.pageContent?.SectionMain;
    },
    sectionEngineers() {
      return this.pageContent?.SectionEngineers;
    },
    sectionCertificates() {
      return this.pageContent?.SectionCertificates;
    },
    sectionPlanes() {
      return this.pageContent?.SectionPlanes;
    },
    features() {
      return this.sectionMain?.Item;
    },
    planesArray() {
      const idsOrder = this.sectionPlanes?.Planes?.map((el) => el.id);
      const result = idsOrder?.map((el) => this.planes?.find((plane) => plane.id === el));
      return result;
    },
  },
  watch: {
    currentLang() {
      this.getPageContent();
      this.getPlanes();
    },
  },
  beforeMount() {
    this.getPageContent();
    this.getPlanes();
  },
  mounted() {
    window.addEventListener('scroll', this.closeAllTooltips);
  },
  unmounted() {
    window.removeEventListener('scroll', this.closeAllTooltips);
  },
};
</script>

<style lang="scss" scoped>
.about-page {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @include screen("md", max) {
    height: auto;
  }

  ::v-deep(> .swiper-wrapper) {
    flex-direction: column;
    transition-timing-function: cubic-bezier(0.42, -0.01, 0.84, 0.64);
  }
}

.about-lead {
  display: flex;
  box-sizing: border-box;

  @include wRule(
    padding-bottom,
    (
      xl: 74,
      lg: 55,
      md: 126,
      sm: 175,
    )
  );

  ::v-deep(.breadcrumbs) {
    @include wRule(
      margin-bottom,
      (
        xl: 50,
        lg: 50,
        md: 50,
        sm: 50,
      )
    );
  }

  ::v-deep(.page-section__content) {
    display: flex;
    flex-direction: column;
  }

  .container {
    display: flex;

    @include rule(
      margin,
      (
        xl: auto 0,
        sm: pxtovw(217, sm) 0 0,
      )
    );

    @include screen("md", max) {
      flex-wrap: wrap;
    }

    @include screen("xxl", min) {
      justify-content: space-between;
    }
  }

  h3 {
    @include screen("xxl", min) {
      max-width: 680px;
    }
  }

  .column {
    display: block;
    box-sizing: border-box;

    @include rule(
      width,
      (
        xl: 50%,
        md: 100%,
      )
    );

    &_start {
      @include rule(
        padding-right,
        (
          md: pxtovw(50, md),
        )
      );

      @include screen("xxl", min) {
        padding-right: 5.556%;
      }
    }

    &_end {
      @include rule(
        padding-left,
        (
          xl: pxtovw(60),
          lg: pxtovw(20, lg),
          md: 0,
        )
      );
      @include rule(
        padding-right,
        (
          md: pxtovw(234, md),
          sm: 0,
        )
      );

      @include screen("xxl", min) {
        padding-left: 8.33%;
      }
    }
  }

  .features {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);

    @include wRule(
      margin-top,
      (
        xl: 9,
        lg: 9,
        md: 71,
        sm: 98,
      )
    );

    @include wRule(
      grid-row-gap,
      (
        xl: 165,
        lg: 104,
        md: 60,
        sm: 53,
      )
    );
  }

  .feature {
    box-sizing: border-box;

    &:nth-child(even) {
      @include wRule(
        padding-left,
        (
          xl: 55,
          lg: 20,
          md: 40,
          sm: 35,
        )
      );
    }
  }
}

.about-engineers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  box-sizing: border-box;

  @include wRule(
    padding-top,
    (
      xl: 50,
      lg: 50,
      md: 50,
      sm: 761,
    )
  );

  @include wRule(
    padding-bottom,
    (
      xl: 188,
      lg: 134,
      md: 136,
      sm: 102,
    )
  );

  .column {
    @include wRule(
      width,
      (
        xl: 425,
        lg: 385,
        md: 340,
        sm: 100%,
      )
    );
  }
}

.about-certificates {
  @include wRule(
    padding-top,
    (
      xl: 240,
      lg: 145,
      md: 160,
      sm: 99,
    )
  );
  @include wRule(
    padding-bottom,
    (
      xl: 100,
      lg: 145,
      md: 160,
      sm: 78,
    )
  );

  @include screen("xxl", min) {
    padding-top: 260px;
  }

  h3 {
    @include screen("sm", max) {
      width: 100%;
    }
  }

  ::v-deep(.page-section__content) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;

    @include screen("sm", min) {
      align-items: flex-start;
      align-content: flex-start;
    }
  }

  .page-section {
    &__header {
      @include screen("sm", max) {
        flex-wrap: wrap;
      }
    }
  }

  .slider-navigation {
    @include screen("sm", max) {
      margin-left: auto;
      margin-top: pxtovw(40, sm);
    }
  }

  .certificates-slider {
    @include wRule(
      margin-top,
      (
        xl: 188,
        lg: 125,
        md: 125,
        sm: 25,
      )
    );

    @include screen("xxl", min) {
      margin-top: 260px;
    }
  }
}

.about-airplanes {
  @include wRule(
    padding,
    (
      xl: 240 0 68,
      lg: 145 0 50,
      md: 160 0 100,
      sm: 99 0 76,
    )
  );

  @include screen("xxl", min) {
    padding-top: 260px;
  }

  ::v-deep(.page-section__content) {
    position: static;
  }

  ::v-deep(.page-section__header) {
    position: relative;
    z-index: 3;
  }

  .plane {
    @include wRule(
      margin-top,
      (
        xl: 86,
        lg: 68,
        md: 58,
        sm: 70,
      )
    );
  }
}
</style>
