<template>
  <div class="page-container">
    <about-page
      @page-slider-scroll-down="pageScrollDown"
      @page-slider-scroll-up="pageScrollUp"
      @page-slider-reach-footer="$emit('pageSliderReachFooter')"
      @page-slider-go-away-footer="$emit('pageSliderGoAwayFooter')"
    />
  </div>
</template>

<script>
import AboutPage from '../components/AboutPage.vue';

export default {
  name: 'About',
  components: { AboutPage },
  emits: [
    'pageSliderScrollDown',
    'pageSliderScrollUp',
    'pageSliderReachFooter',
    'pageSliderGoAwayFooter',
  ],
  methods: {
    pageScrollDown(slideNumber) {
      this.$emit('pageSliderScrollDown', slideNumber);
    },
    pageScrollUp(slideNumber) {
      this.$emit('pageSliderScrollUp', slideNumber);
    },
  },
};
</script>
