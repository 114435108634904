<template>
  <vue-collapsible-panel-group
    class="plane-accordion"
    accordion
  >
    <vue-collapsible-panel
      v-for="(panel, panelId) in content"
      :key="panel?.PlaneDescriptionListItemTitle"
      :expanded="false"
    >
      <template #title>
        <span class="count">{{panelId + 1}}.</span>
        <span class="name">{{panel?.PlaneDescriptionListItemTitle}}</span>
      </template>
      <template #content>
        {{panel?.PlaneDescriptionListItemText}}
      </template>
    </vue-collapsible-panel>
  </vue-collapsible-panel-group>
</template>

<script>
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from '@dafcoe/vue-collapsible-panel';

export default {
  name: 'PlaneAccordion',
  props: {
    content: Array,
  },
  components: {
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
  },
};
</script>

<style lang="scss" scoped>
.plane-accordion {
  @include rule(
    display,
    (
      xl: none,
      sm: block,
    )
  );

  ::v-deep(.vcp) {
    line-height: 1.6;

    @include wRule(
      font-size,
      (
        sm: 30,
      )
    );

    &:not(:last-child) {
      border-bottom: 1px solid color(accordion-border);
    }
  }

  ::v-deep(.vcp__header) {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include wRule(
      height,
      (
        sm: 95,
      )
    );
  }

  ::v-deep(.vcp__header-title) {
    width: 100%;
  }

  ::v-deep(.vcp__header-icon) {
    display: none;
  }

  ::v-deep(.vcp__body) {
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.5, 0.25, 0, 1);

    &.slide-enter-from,
    &.slide-leave-to {
      opacity: 0.25;
      transform: translate3d(0, 0, 0);
    }
  }

  ::v-deep(.vcp__body-content) {
    display: block;
    box-sizing: border-box;
    width: 100%;

    @include wRule(
      padding,
      (
        sm: 0 0 28 45,
      )
    );

    &.slide-enter-from,
    &.slide-leave-to {
      opacity: 0.25;
      transform: translate3d(0, 0, 0);
    }
  }

  ::v-deep(.vcp .count) {
    display: inline-block;
    font-weight: 400;

    @include wRule(
      width,
      (
        sm: 45,
      )
    );
  }

  ::v-deep(.vcp .name) {
    display: inline-block;
    font-weight: 700;
  }
}
</style>
